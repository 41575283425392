import React, { useEffect, useState } from "react";
import { Typography, Stack, Box } from "@mui/material";
import darkTheme from "../../../src/theme";
import Image from "next/image";
// import ItemImage from "../../../public/images/home/ItemImage.png";
import ItemImage from "../../../public/images/home/item-1-image.svg";
import Link from "next/link";

function ItemOne({ box1Hovered, box2Hovered, setbox1Hovered, setbox2Hovered }) {

  const ItemOneContainerHoveredBigDesktop = {
    width: "100% !important",
    transition: "0.3s",
    background: darkTheme.palette.primary.main,
    color: darkTheme.palette.common.black,
    display: { xl: "block", xs: "none" },
    p: 4,
    mr: 1,
    borderRadius: 3,
    "& h4": {
      fontSize: 28,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 18,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 23,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 12,
      mb: 1,
    },
    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      // visibility: "visible",
      display: "block",
    },
    "& .imageContainer": {
      display: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      my: 4,
      mt: 4,
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.common.black,
        color: darkTheme.palette.common.white,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
  };

  const ItemOneContainerNormalBigDesktop = {
    display: { xl: "block", xs: "none" },
    width: "50%",
    p: 4,
    mr: 1,
    borderRadius: 3,
    background: darkTheme.palette.background.alternate,
    color: darkTheme.palette.common.white,
    transition: "0.3s",
    "& h4": {
      fontSize: 28,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 18,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 23,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 12,
      mb: 1,
    },
    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      // visibility: "hidden",
      display: "none",
    },
    "& .imageContainer": {
      display: "none",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      my: 4,
      mt: "38rem !important",
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.primary.main,
        color: darkTheme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
    // "&:hover": {
    //   width: "100% !important",
    //   transition: "0.3s",
    //   background: darkTheme.palette.primary.main,
    //   color: darkTheme.palette.common.black,
    //   "& .imageContainerWrapper": {
    //     // visibility: "visible",
    //     display: "block",
    //   },
    //   "& .imageContainer": {
    //     display: "hidden",
    //     "& img": {
    //       width: "100%",
    //       height: "100%",
    //     },
    //   },
    //   "& .buttonContainer": {
    //     "& a": {
    //       background: darkTheme.palette.common.black,
    //       color: darkTheme.palette.common.white,
    //     },
    //   },
    // },
  };

  const ItemOneContainerHovered = {
    width: "100% !important",
    transition: "0.3s",
    background: darkTheme.palette.primary.main,
    color: darkTheme.palette.common.black,
    display: { xl: "none", lg: "block", xs: "none" },
    p: 4,
    mr: 1,
    borderRadius: 3,
    "& h4": {
      fontSize: 28,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 18,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 23,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 12,
      mb: 1,
    },
    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      // visibility: "visible",
      display: "block",
    },
    "& .imageContainer": {
      display: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      my: 4,
      mt: 4,
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.common.black,
        color: darkTheme.palette.common.white,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
  };

  const ItemOneContainerNormal = {
    display: { xl: "none", lg: "block", xs: "none" },
    width: "50%",
    p: 4,
    mr: 1,
    borderRadius: 3,
    background: darkTheme.palette.background.alternate,
    color: darkTheme.palette.common.white,
    transition: "0.3s",
    "& h4": {
      fontSize: 28,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 18,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 23,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 12,
      mb: 1,
    },
    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      // visibility: "hidden",
      display: "none",
    },
    "& .imageContainer": {
      display: "none",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      my: 4,
      mt: "47.79rem !important",
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.primary.main,
        color: darkTheme.palette.common.black,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
    // "&:hover": {
    //   width: "100% !important",
    //   transition: "0.3s",
    //   background: darkTheme.palette.primary.main,
    //   color: darkTheme.palette.common.black,
    //   "& .imageContainerWrapper": {
    //     // visibility: "visible",
    //     display: "block",
    //   },
    //   "& .imageContainer": {
    //     display: "hidden",
    //     "& img": {
    //       width: "100%",
    //       height: "100%",
    //     },
    //   },
    //   "& .buttonContainer": {
    //     "& a": {
    //       background: darkTheme.palette.common.black,
    //       color: darkTheme.palette.common.white,
    //     },
    //   },
    // },
  };

  const ItemOneContainerTab = {
    display: { lg: "none", md: "block", xs: "none" },
    width: "100%",
    p: 4,
    mr: 1,
    mb: 2,
    borderRadius: 3,
    background: darkTheme.palette.primary.main,
    color: darkTheme.palette.common.black,
    transition: "0.3s",
    "& h4": {
      fontSize: 25,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 18,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 20,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 14,
      mb: 1,
    },

    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      visibility: "hidden",
    },
    "& .imageContainer": {
      display: "none",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      mt: 4,
      textAlign: "center",
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.common.black,
        color: darkTheme.palette.common.white,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        mx: "auto !important",
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
  };

  const ItemOneContainerMobile = {
    display: { md: "none" },
    width: "100%",
    p: 4,
    mr: 1,
    mb: 2,
    borderRadius: 3,
    background: darkTheme.palette.primary.main,
    color: darkTheme.palette.common.black,
    transition: "0.3s",
    "& h4": {
      fontSize: 25,
      textAlign: "left",
      fontWeight: 700,
      mb: 3,
    },
    "& h6": {
      fontSize: 14,
      textAlign: "left",
      fontWeight: 400,
    },
    "& strong": {
      fontWeight: `500 !important`,
      fontSize: 18,
      mb: 1,
    },
    "& small": {
      fontWeight: `100 !important`,
      fontSize: 12,
      mb: 1,
    },

    "& h5": {
      fontSize: 14,
      textAlign: "left",
      color: darkTheme.palette.common.black,
    },
    "& ul li": {
      marginBottom: "15px",
    },
    "& .half-width": {
      width: {
        md: "70%",
        sm: "90%",
      },
    },
    "& .imageContainerWrapper": {
      visibility: "hidden",
    },
    "& .imageContainer": {
      display: "none",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    "& .buttonContainer": {
      mt: 4,
      textAlign: "center",
      "& a": {
        alignItems: "center",
        textDecoration: "none",
        background: darkTheme.palette.common.black,
        color: darkTheme.palette.common.white,
        fontSize: 14,
        fontWeight: 500,
        px: 3,
        py: 1.25,
        borderRadius: 2.5,
        mx: "auto !important",
        [darkTheme.breakpoints.down("md")]: {
          fontSize: 16,
          fontWeight: 700,
          px: 1.5,
        },
      },
    },
    // "&:hover": {
    //   width: "100% !important",
    //   transition: "0.3s",
    //   background: darkTheme.palette.primary.main,
    //   color: darkTheme.palette.common.black,
    //   "& .imageContainerWrapper": {
    //     visibility: "visible",
    //   },
    //   "& .imageContainer": {
    //     display: "hidden",
    //     "& img": {
    //       width: "100%",
    //       height: "100%",
    //     },
    //   },
    //   "& .buttonContainer": {
    //     "& a": {
    //       background: darkTheme.palette.common.black,
    //       color: darkTheme.palette.common.white,
    //     },
    //   },
    // },
  };

  return (
    <>
      {/* BIG DESKTOP */}
      <Typography
        component="div"
        sx={box1Hovered ? ItemOneContainerHoveredBigDesktop : ItemOneContainerNormalBigDesktop}
        onMouseEnter={() => {
          setbox1Hovered(true);
          setbox2Hovered(false);
        }}
      >
        <Typography component="div">
          <Typography variant="h4">For Live Music Venus</Typography>
          <Typography variant="h6" className="half-width">
            Clubr Backstage for Live Music Venues Empower them with
            Independence, Exclusivity, and Unmatched Experience.
          </Typography>
          <Box className="imageContainerWrapper">
            <Typography
              variant="h6"
              className="half-width"
              sx={{ mt: 5, mb: 3 }}
            >
              Free White Label Platform + Game-Changing Additions
            </Typography>
            <Stack
              display="flex"
              // className="imageContainerWrapper"
              sx={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <Typography component="ul" sx={{ pl: 2 }} className="half-width">
                <li>
                  <Typography variant="h6" component="strong">
                    Whatsapp Automation:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 0, mt: 1 }}
                  >
                    Free WhatsApp Business Integration +
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    WhatsApp Messaging on Actuals
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Segmentation & CRM
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Precision Marketing with Data Segmentation
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Marketing Automation
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Instant Campaigns with Ready-Made Templates
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    AI Chatbot
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Streamline Operations, Reduce Calls, and Provide 24/7
                    Support
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Experience Analytics
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Uncover Key Customer Trends
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Digital Vallet
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Deliver a 5-Star Experience for Unmatched Customer Delight
                  </Typography>
                </li>
              </Typography>
              <Typography component="div" className="imageContainer">
                <Image src={ItemImage} width={400} height={480} />
              </Typography>
            </Stack>
          </Box>
          <div className="buttonContainer">
            <Link href="/selfOnboarding">
              <a>Subscription Starts from 2,500 PM</a>
            </Link>
          </div>
        </Typography>
      </Typography>

      {/* DESKTOP */}
      <Typography
        component="div"
        sx={box1Hovered ? ItemOneContainerHovered : ItemOneContainerNormal}
        onMouseEnter={() => {
          setbox1Hovered(true);
          setbox2Hovered(false);
        }}
      >
        <Typography component="div">
          <Typography variant="h4">For Live Music Venus</Typography>
          <Typography variant="h6" className="half-width">
            Clubr Backstage for Live Music Venues Empower them with
            Independence, Exclusivity, and Unmatched Experience.
          </Typography>
          <Box className="imageContainerWrapper">
            <Typography
              variant="h6"
              className="half-width"
              sx={{ mt: 5, mb: 3 }}
            >
              Free White Label Platform + Game-Changing Additions
            </Typography>
            <Stack
              display="flex"
              // className="imageContainerWrapper"
              sx={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <Typography component="ul" sx={{ pl: 2 }} className="half-width">
                <li>
                  <Typography variant="h6" component="strong">
                    Whatsapp Automation:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 0, mt: 1 }}
                  >
                    Free WhatsApp Business Integration +
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    WhatsApp Messaging on Actuals
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Segmentation & CRM
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Precision Marketing with Data Segmentation
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Marketing Automation
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Instant Campaigns with Ready-Made Templates
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    AI Chatbot
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Streamline Operations, Reduce Calls, and Provide 24/7
                    Support
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Experience Analytics
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Uncover Key Customer Trends
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Digital Vallet
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Deliver a 5-Star Experience for Unmatched Customer Delight
                  </Typography>
                </li>
              </Typography>
              <Typography component="div" className="imageContainer">
                <Image src={ItemImage} width={400} height={480} />
              </Typography>
            </Stack>
          </Box>
          <div className="buttonContainer">
            <Link href="/selfOnboarding">
              <a>Subscription Starts from 2,500 PM</a>
            </Link>
          </div>
        </Typography>
      </Typography>
      {/* TAB */}
      <Typography component="div" sx={ItemOneContainerTab}>
        <Typography component="div">
          <Typography variant="h4">For Live Music Venus</Typography>
          <Typography variant="h6">
            Clubr Backstage for Live Music Venues Empower them with
            Independence, Exclusivity, and Unmatched Experience.
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Image src={ItemImage} width={400} height={480} />
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
              Free White Label Platform + Game-Changing Additions
            </Typography>
            <Stack
              display="flex"
              sx={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>
                  <Typography variant="h6" component="strong">
                    Whatsapp Automation:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 0, mt: 1 }}
                  >
                    Free WhatsApp Business Integration +
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    WhatsApp Messaging on Actuals
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Segmentation & CRM
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Precision Marketing with Data Segmentation
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Marketing Automation
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Instant Campaigns with Ready-Made Templates
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    AI Chatbot
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Streamline Operations, Reduce Calls, and Provide 24/7
                    Support
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Experience Analytics
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Uncover Key Customer Trends
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Digital Vallet
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Deliver a 5-Star Experience for Unmatched Customer Delight
                  </Typography>
                </li>
              </Typography>
              <Typography component="div" className="imageContainer">
                <Image src={ItemImage} width={400} height={480} />
              </Typography>
            </Stack>
          </Box>
          <div className="buttonContainer">
            <Link href="/selfOnboarding">
              <a>Starts from 2,500 PM</a>
            </Link>
          </div>
        </Typography>
      </Typography>
      {/* PHONE */}
      <Typography component="div" sx={ItemOneContainerMobile}>
        <Typography component="div">
          <Typography variant="h4">For Live Music Venus</Typography>
          <Typography variant="h6">
            Clubr Backstage for Live Music Venues Empower them with
            Independence, Exclusivity, and Unmatched Experience.
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Image src={ItemImage} width={400} height={480} />
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mt: 5, mb: 3 }}>
              Free White Label Platform + Game-Changing Additions
            </Typography>
            <Stack
              display="flex"
              sx={{ justifyContent: "space-between", flexDirection: "row" }}
            >
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>
                  <Typography variant="h6" component="strong">
                    Whatsapp Automation:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 0, mt: 1 }}
                  >
                    Free WhatsApp Business Integration +
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    WhatsApp Messaging on Actuals
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Segmentation & CRM
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Precision Marketing with Data Segmentation
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Marketing Automation
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Instant Campaigns with Ready-Made Templates
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    AI Chatbot
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Streamline Operations, Reduce Calls, and Provide 24/7
                    Support
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Customer Experience Analytics
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Uncover Key Customer Trends
                  </Typography>
                </li>
                <li>
                  <Typography variant="h6" component="strong">
                    Digital Vallet
                  </Typography>
                  <Typography
                    component="h6"
                    variant="small"
                    sx={{ mb: 2, mt: 0 }}
                  >
                    Deliver a 5-Star Experience for Unmatched Customer Delight
                  </Typography>
                </li>
              </Typography>
              <Typography component="div" className="imageContainer">
                <Image src={ItemImage} width={400} height={480} />
              </Typography>
            </Stack>
          </Box>
          <div className="buttonContainer">
            <Link href="/selfOnboarding">
              <a>Starts from 2,500 PM</a>
            </Link>
          </div>
        </Typography>
      </Typography>
    </>
  );
}

export default ItemOne;
