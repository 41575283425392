import React, { useEffect } from "react";
import { Typography, Stack, Button, Box } from "@mui/material";
import darkTheme from "../../../src/theme";

const itemContainer = {
  pt: 2,
  mx: 4,
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  textAlign: "center !important",
  cursor: "pointer",
  "& h5": {
    fontSize: 16,
    fontWeight: 500,
    color: darkTheme.palette.common.white,
    mt: 2,
  },
  "& img": {
    margin: "0 auto",
    p: 1.5,
    borderRadius: 2,
  },
  // "& :hover": {
  //   // "& img": {
  //   background: darkTheme.palette.background.alternate,
  //   // },
  // },
};

const itemContainerMobile = {
  pt: 1,
  mx: 1,
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  textAlign: "center !important",
  cursor: "pointer",
  "& h5": {
    fontSize: 13,
    fontWeight: 500,
    color: darkTheme.palette.common.white,
    mt: 2,
  },
  "& img": {
    margin: "0 auto",
    p: 1.5,
    borderRadius: 2,
  },
  // "& :hover": {
  //   // "& img": {
  //   background: darkTheme.palette.background.alternate,
  //   // },
  // },
};

const buttonStyle = {
  background: "transparent",
  outline: "none",
  border: "none",
  p: 3,
};

const buttonStyleMobile = {
  background: "transparent",
  outline: "none",
  border: "none",
  p: 0,
  pt: 0.8,
  width: "fit-content",
};

function BannerBottomItem(props) {
  const { data, selectedItem, setselectedItem } = props;

  return (
    <>
      <Box sx={{ display: { md: "block", xs: "none" } }}>
        <Typography
          component="div"
          sx={itemContainer}
          onClick={() => setselectedItem(data)}
        >
          <Stack
            display="flex"
            justifyContent="center"
            sx={{ flexDirection: "column" }}
          >
            <Button
              sx={{
                ...buttonStyle,
                background:
                  selectedItem.id == data.id ? "#1D251E" : "transparent",
                boxShadow:
                  selectedItem.id == data.id
                    ? "0px 0px 16px -2px #16fb48e5"
                    : "none",
              }}
            >
              <span
                style={{
                  color: selectedItem.id == data.id ? "#61fc67" : "#fff",
                }}
              >
                {data.icon}
              </span>
            </Button>
            <Typography variant="h5" sx={{ width: "8rem" }}>
              {data.smallText}
            </Typography>
          </Stack>
        </Typography>
      </Box>
      <Box sx={{ display: { md: "none", sm: "block", xs: "none" } }}>
        <Typography
          component="div"
          sx={itemContainerMobile}
          onClick={() => setselectedItem(data)}
        >
          <Stack
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            sx={{ flexDirection: "column" }}
          >
            <Button
              sx={{
                ...buttonStyleMobile,
                background:
                  selectedItem.id == data.id ? "#1D251E" : "transparent",
                boxShadow:
                  selectedItem.id == data.id
                    ? "0px 0px 12px -2px #16fb48e5"
                    : "none",
              }}
            >
              <span
                style={{
                  color: selectedItem.id == data.id ? "#61fc67" : "#fff",
                }}
              >
                {data.icon}
              </span>
            </Button>
            <Typography variant="h5" sx={{ width: "8rem" }}>
              {data.smallText}
            </Typography>
          </Stack>
        </Typography>
      </Box>
      <Box sx={{ display: { sm: "none" } }}>
        <Typography
          component="div"
          sx={itemContainerMobile}
          onClick={() => setselectedItem(data)}
        >
          <Stack
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            sx={{ flexDirection: "column" }}
          >
            <Button
              sx={{
                ...buttonStyleMobile,
                background:
                  selectedItem.id == data.id ? "#1D251E" : "transparent",
                boxShadow:
                  selectedItem.id == data.id
                    ? "0px 0px 12px -2px #16fb48e5"
                    : "none",
              }}
            >
              <span
                style={{
                  color: selectedItem.id == data.id ? "#61fc67" : "#fff",
                }}
              >
                {data.icon}
              </span>
            </Button>
            <Typography variant="h5" sx={{ width: "8rem" }}>
              {data.smallText}
            </Typography>
          </Stack>
        </Typography>
      </Box>
    </>
  );
}

export default BannerBottomItem;
