import React, { Fragment, useEffect, useState } from "react";
import { Typography, Stack, Box, Container, Button } from "@mui/material";
import darkTheme from "../../src/theme";
import Link from "next/link";
import chevron_right from "../../public/images/home/chevron_right.svg";
import Image from "next/image";
import SectionOneImage from "../../public/images/home/SectionOne.gif";
import SectionTwoImage from "../../public/images/home/INfluencers & Promoters.svg";
import SectionThreeImage from "../../public/images/home/Coupons & Offline sales.svg";
import SectionFourImage from "../../public/images/home/SectionFour.svg";
import SectionFiveImage from "../../public/images/home/Content Distribution.svg";
import SectionSixImage from "../../public/images/home/Access Control.svg";
import SectionSevenImage from "../../public/images/home/Digital Marketing.svg";
import BannerBottomItem from "../../components/newHomeSections/BannerHome/Item";

import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";

import { nanoid } from "nanoid";

import { Navigation, Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const mainIconStyle = {
  cursor: "pointer !important",
  height: { md: 50, xs: 35 },
  width: { md: 50, xs: 35 },
};

const bannerContainer = {
  marginTop: darkTheme.spacing(-3),
  marginBottom: darkTheme.spacing(-2),
  [darkTheme.breakpoints.down("md")]: {
    marginTop: darkTheme.spacing(6),
  },
  "& h1": {
    fontSize: 50,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    lineHeight: "5rem",
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: "unset",
    },
  },

  "& h5": {
    // my: 2,
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: darkTheme.palette.primary.title,
    "& small": {
      fontSize: `14px !important`,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  "& .buttonContainer": {
    my: 4,
    display: "flex",
    justifyContent: "center",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      background: darkTheme.palette.primary.main,
      color: darkTheme.palette.common.black,
      fontSize: 22,
      fontWeight: 900,
      px: 3,
      py: 1.25,
      borderRadius: darkTheme.spacing(2),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 16,
        fontWeight: 700,
        px: 1.5,
      },
    },
  },
};

const bannerContainerDesktop = {
  marginTop: darkTheme.spacing(10),
  marginBottom: darkTheme.spacing(-2),
  [darkTheme.breakpoints.down("md")]: {
    marginTop: darkTheme.spacing(6),
  },
  "& h1": {
    fontSize: 50,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    lineHeight: "5rem",
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: "unset",
    },
  },

  "& h5": {
    // my: 2,
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: darkTheme.palette.primary.title,
    "& small": {
      fontSize: `14px !important`,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  "& .buttonContainer": {
    my: 4,
    display: "flex",
    justifyContent: "center",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      background: darkTheme.palette.primary.main,
      color: darkTheme.palette.common.black,
      fontSize: 22,
      fontWeight: 900,
      px: 3,
      py: 1.25,
      borderRadius: darkTheme.spacing(2),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 16,
        fontWeight: 700,
        px: 1.5,
      },
    },
  },
};

const bannerContainerBigTab = {
  marginTop: darkTheme.spacing(-20),
  marginBottom: darkTheme.spacing(-20),
  [darkTheme.breakpoints.down("md")]: {
    marginTop: darkTheme.spacing(6),
  },
  "& h1": {
    fontSize: 50,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    lineHeight: "5rem",
    // [darkTheme.breakpoints.down("md")]: {
    //   fontSize: 25,
    //   fontWeight: 700,
    //   lineHeight: "unset",
    // },
  },

  "& h5": {
    // my: 2,
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: darkTheme.palette.primary.title,
    "& small": {
      fontSize: `14px !important`,
    },
    // [darkTheme.breakpoints.down("md")]: {
    //   fontSize: 12,
    // },
  },
  "& .buttonContainer": {
    my: 4,
    display: "flex",
    justifyContent: "center",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      background: darkTheme.palette.primary.main,
      color: darkTheme.palette.common.black,
      fontSize: 22,
      fontWeight: 900,
      px: 3,
      py: 1.25,
      borderRadius: darkTheme.spacing(2),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 16,
        fontWeight: 700,
        px: 1.5,
      },
    },
  },
};

const bannerContainerTab = {
  marginTop: darkTheme.spacing(-20),
  marginBottom: darkTheme.spacing(-20),
  "& h1": {
    fontSize: 50,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    lineHeight: "5rem",
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: "unset",
    },
  },

  "& h5": {
    // my: 2,
    fontSize: 23,
    fontWeight: 400,
    textAlign: "center",
    color: darkTheme.palette.primary.title,
    "& small": {
      fontSize: `14px !important`,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  "& .buttonContainer": {
    mt: 4,
    display: "flex",
    justifyContent: "center",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      background: darkTheme.palette.primary.main,
      color: darkTheme.palette.common.black,
      fontSize: 22,
      fontWeight: 900,
      px: 3,
      py: 1.25,
      borderRadius: darkTheme.spacing(2),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 16,
        fontWeight: 700,
        px: 1.5,
      },
    },
  },
};

const bannerContainerMobile = {
  marginTop: darkTheme.spacing(-3),
  marginBottom: darkTheme.spacing(10),
  [darkTheme.breakpoints.down("md")]: {
    marginTop: darkTheme.spacing(6),
  },
  "& h1": {
    fontSize: 50,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    lineHeight: "5rem",
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "unset",
    },
  },

  "& h5": {
    // my: 2,
    fontSize: 20,
    fontWeight: 400,
    textAlign: "center",
    color: darkTheme.palette.primary.title,
    "& small": {
      fontSize: `14px !important`,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  "& .buttonContainer": {
    my: 4,
    display: "flex",
    justifyContent: "center",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      background: darkTheme.palette.primary.main,
      color: darkTheme.palette.common.black,
      fontSize: 22,
      fontWeight: 900,
      px: 3,
      py: 1.25,
      borderRadius: darkTheme.spacing(2),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 16,
        fontWeight: 700,
        px: 1.5,
      },
    },
  },
};

const SectionOneContainer = {
  // background: "#202020",
  background: "#1e1e1e",
  p: 5,
  mt: 2,
  mb: 10,
  borderRadius: 6,
  [darkTheme.breakpoints.down("md")]: {
    mt: 5,
    mb: 10,
  },
  "& h2": {
    fontSize: 40,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    textTransform: "capitalize",
    mb: 2,
    "& span": {
      color: darkTheme.palette.primary.main,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 25,
      fontWeight: 500,
    },
  },
  "& h4": {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: darkTheme.palette.common.white,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
};

const SectionOneContainerMobile = {
  background: "#202020",
  px: 2,
  py: 2,
  mt: 0,
  mb: 10,
  borderRadius: 6,
  [darkTheme.breakpoints.down("md")]: {
    mt: 0,
    mb: 0,
  },
  "& h2": {
    fontSize: 40,
    textAlign: "center",
    fontWeight: 700,
    color: darkTheme.palette.common.white,
    textTransform: "capitalize",
    mb: 2,
    "& span": {
      color: darkTheme.palette.primary.main,
    },
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
  "& h4": {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: darkTheme.palette.common.white,
    [darkTheme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
};

const imgContainer = {
  mt: 3,
  mx: "auto",
  [darkTheme.breakpoints.down("md")]: {
    transform: `scale(1.35)`,
  },
};

const imgContainerTab = {
  mt: 3,
  mx: "auto",
};

const imgContainerMobile = {
  mt: 3,
  mx: "auto",
  [darkTheme.breakpoints.down("md")]: {
    // transform: `scale(1.15)`,
  },
};

const BannerHome = () => {
  const [items, setitems] = useState([
    {
      id: 1,
      smallText: "Ticketing & Tables",
      icon: <LocalActivityOutlinedIcon sx={mainIconStyle} />,
      title: "Streamline Table Bookings with Custom Layouts",
      subTitle: "Sell Online, Send Payment Links or Assign Offline with Ease.",
      image: SectionOneImage,
    },
    {
      id: 2,
      smallText: "Influencers & Promoters",
      icon: <GroupsOutlinedIcon sx={mainIconStyle} />,
      title: "Influencers & Promoter Tracking ",
      subTitle:
        "Engage Influencers with Trackable Links and Identify Your Top Promoters.",
      image: SectionTwoImage,
    },
    {
      id: 3,
      smallText: "Coupons & Offline Sales",
      icon: <LocalOfferOutlinedIcon sx={mainIconStyle} />,
      title: "Scale unlimited with Offline Sales & Coupons ",
      subTitle:
        "Manage Offline Ticketing Agents with Integrated Bookings and Real-Time Reporting.",
      image: SectionThreeImage,
    },
    {
      id: 4,
      smallText: "Data & Analytics",
      icon: <DonutSmallOutlinedIcon sx={mainIconStyle} />,
      title: "Critical Insights for Smarter Decisions.",
      subTitle: "Real-Time Sales, Check-In Stats, and Gate Intelligence",
      image: SectionFourImage,
    },
    {
      id: 5,
      smallText: "Content Distribution",
      icon: <MemoryOutlinedIcon sx={mainIconStyle} />,
      title: "Integrated Discovery Platform - One Upload, Endless Reach",
      subTitle:
        "Distribute Your Event Across Google, Social Media, WhatsApp, and More.",
      image: SectionFiveImage,
    },
    {
      id: 6,
      smallText: "Access Control",
      icon: <QrCodeScannerOutlinedIcon sx={mainIconStyle} />,
      title: "Seamless Access Control with Analytics",
      subTitle:
        "Manage Guestlists, Gate Sales, and Group Check-ins with Real-Time Ticket Authentication.",
      image: SectionSixImage,
    },
    {
      id: 7,
      smallText: "Digital Marketing",
      icon: <DatasetLinkedOutlinedIcon sx={mainIconStyle} />,
      title: "Digital Play for Every Event",
      subTitle:
        "Enterprise-Level Digital Marketing, Made Accessible for Every Event.",
      image: SectionSevenImage,
    },
  ]);

  const [selectedItem, setselectedItem] = useState(items[3]);

  return (
    <>
      {/* BIG DESKTOP */}
      <Box
        sx={{ width: "85%", mx: "auto", display: { xl: "block", xs: "none" } }}
      >
        <Typography
          component="div"
          className="myvideo"
          sx={{ ...bannerContainer }}
        >
          <Typography component="div" className="myVideoContent">
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "80%",
                margin: `0 auto`,
              }}
            >
              <Typography variant="h1" sx={{ mb: 0 }}>
                Own Your Event Ticketing
              </Typography>
              <Typography
                variant="h1"
                sx={{ mb: 2, color: "#61fc67 !important" }}
              >
                With a FREE Web App
              </Typography>
              <Typography component="div" sx={{ width: "70%" }}>
                <Typography component="h5" className="mx-auto" sx={{ mb: 0 }}>
                  <strong style={{ fontSize: "20px" }}>
                    Beyond Ticketing:{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "16px", fontWeight: 200 }}>
                    Our White-label Platform{" "}
                  </span>
                </Typography>
                <Typography component="h5" className="mx-auto" sx={{ mt: 0 }}>
                  <span style={{ fontSize: "16px", fontWeight: 200 }}>
                    Delivers a Complete Brand Experience for Your Events{" "}
                  </span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: -3,
                    mt: 4,
                  }}
                >
                  <Link href="/selfOnboarding" passHref>
                    <Button
                      size="medium"
                      sx={{
                        background: "#61FC67",
                        my: 4,
                        color: "#000",
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        px: 6,
                        "&:hover": {
                          background: "#61FC67",
                          color: "#000",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      <>
                        Get Started. It&apos;s FREE{" "}
                        <Image
                          src={chevron_right}
                          alt=""
                          width={35}
                          height={35}
                          style={{ marginLeft: 10 }}
                        />
                      </>
                    </Button>
                  </Link>
                </Box>
                <Typography component="h5">
                  <span style={{ fontSize: "16px" }}>
                    No Setup Costs or Subscription Fees, Just 5% Success Fee.
                  </span>
                </Typography>
              </Typography>
            </Stack>
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            // my: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Stack
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%", flexDirection: "row", mb: 5 }}
          >
            {items.map((e) => (
              <BannerBottomItem
                key={nanoid(10)}
                data={e}
                selectedItem={selectedItem}
                setselectedItem={setselectedItem}
              />
            ))}
            {/* <Swiper
              modules={[Navigation, FreeMode]}
              spaceBetween={1}
              slidesPerView={7}
              // freemode={true}
              navigation
              effect={"fade"}
              calculateHeight={true}
              autoplay={false}
              loop={false}
              // breakpoints={{
              //   1023: {
              //     width: 1023,
              //     slidesPerView: 3,
              //   },
              //   990: {
              //     width: 990,
              //     slidesPerView: 3,
              //   },
              //   768: {
              //     width: 768,
              //     slidesPerView: 3,
              //   },
              //   425: {
              //     width: 425,
              //     slidesPerView: 3,
              //   },
              // }}
            >
              {items.map((e) => (
                <SwiperSlide key={nanoid(30)}>
                  <BannerBottomItem
                    data={e}
                    selectedItem={selectedItem}
                    setselectedItem={setselectedItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper> */}
          </Stack>
        </Typography>

        <Box sx={SectionOneContainer}>
          <Box>
            <Typography
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Stack
                display="flex"
                justifyContent="center"
                sx={{ width: { md: "80%", sm: "100%" } }}
              >
                <Typography variant="h2">
                  <span>{selectedItem.title}</span>
                </Typography>
                <Typography variant="h4">{selectedItem.subTitle}</Typography>
                <Typography component="div" sx={imgContainer}>
                  <Image
                    src={selectedItem.image}
                    alt="section one"
                    style={{ width: "100%" }}
                  />
                </Typography>
              </Stack>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* DESKTOP */}
      <Box
        sx={{ width: "85%", mx: "auto", display: { xl: "none", lg: "block", xs: "none" } }}
      >
        <Typography
          component="div"
          className="myvideo"
          sx={{ ...bannerContainerDesktop }}
        >
          <Typography component="div" className="myVideoContent">
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "80%",
                margin: `0 auto`,
              }}
            >
              <Typography variant="h1" sx={{ mb: 0 }}>
                Own Your Event Ticketing
              </Typography>
              <Typography
                variant="h1"
                sx={{ mb: 2, color: "#61fc67 !important" }}
              >
                With a FREE Web App
              </Typography>
              <Typography component="div" sx={{ width: "70%" }}>
                <Typography component="h5" className="mx-auto" sx={{ mb: 0 }}>
                  <strong style={{ fontSize: "20px" }}>
                    Beyond Ticketing:{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "16px", fontWeight: 200 }}>
                    Our White-label Platform{" "}
                  </span>
                </Typography>
                <Typography component="h5" className="mx-auto" sx={{ mt: 0 }}>
                  <span style={{ fontSize: "16px", fontWeight: 200 }}>
                    Delivers a Complete Brand Experience for Your Events{" "}
                  </span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: -3,
                    mt: 4,
                  }}
                >
                  <Link href="/selfOnboarding" passHref>
                    <Button
                      size="medium"
                      sx={{
                        background: "#61FC67",
                        my: 4,
                        color: "#000",
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        px: 6,
                        "&:hover": {
                          background: "#61FC67",
                          color: "#000",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      <>
                        Get Started. It&apos;s FREE{" "}
                        <Image
                          src={chevron_right}
                          alt=""
                          width={35}
                          height={35}
                          style={{ marginLeft: 10 }}
                        />
                      </>
                    </Button>
                  </Link>
                </Box>
                <Typography component="h5">
                  <span style={{ fontSize: "16px" }}>
                    No Setup Costs or Subscription Fees, Just 5% Success Fee.
                  </span>
                </Typography>
              </Typography>
            </Stack>
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            // my: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Stack
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%", flexDirection: "row", mb: 5 }}
          >
            {/* {items.map((e) => (
              <BannerBottomItem
                key={nanoid(10)}
                data={e}
                selectedItem={selectedItem}
                setselectedItem={setselectedItem}
              />
            ))} */}
            <Swiper
              modules={[Navigation, FreeMode]}
              spaceBetween={1}
              slidesPerView={7}
              // freemode={true}
              navigation
              effect={"fade"}
              calculateHeight={true}
              autoplay={false}
              loop={false}
              // breakpoints={{
              //   1023: {
              //     width: 1023,
              //     slidesPerView: 3,
              //   },
              //   990: {
              //     width: 990,
              //     slidesPerView: 3,
              //   },
              //   768: {
              //     width: 768,
              //     slidesPerView: 3,
              //   },
              //   425: {
              //     width: 425,
              //     slidesPerView: 3,
              //   },
              // }}
            >
              {items.map((e) => (
                <SwiperSlide key={nanoid(30)}>
                  <BannerBottomItem
                    data={e}
                    selectedItem={selectedItem}
                    setselectedItem={setselectedItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Stack>
        </Typography>

        <Box sx={SectionOneContainer}>
          <Box>
            <Typography
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Stack
                display="flex"
                justifyContent="center"
                sx={{ width: { md: "80%", sm: "100%" } }}
              >
                <Typography variant="h2">
                  <span>{selectedItem.title}</span>
                </Typography>
                <Typography variant="h4">{selectedItem.subTitle}</Typography>
                <Typography component="div" sx={imgContainer}>
                  <Image
                    src={selectedItem.image}
                    alt="section one"
                    style={{ width: "100%" }}
                  />
                </Typography>
              </Stack>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* BIG TAB */}
      <Box
        sx={{
          width: "85%",
          mx: "auto",
          display: { lg: "none", md: "block", xs: "none" },
        }}
      >
        <Typography
          component="div"
          className="myvideo"
          sx={{ ...bannerContainerBigTab }}
        >
          <Typography component="div" className="myVideoContent">
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "80%",
                margin: `0 auto`,
              }}
            >
              <Typography variant="h1" sx={{ mb: 0 }}>
                Own Your Event Ticketing
              </Typography>
              <Typography
                variant="h1"
                sx={{ mb: 2, color: "#61fc67 !important" }}
              >
                With a FREE Web App
              </Typography>
              <Typography component="div" sx={{ width: "100%" }}>
                <Typography component="h5" className="mx-auto" sx={{ mb: 0 }}>
                  <strong style={{ fontSize: "22px" }}>
                    Beyond Ticketing:{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "18px", fontWeight: 200 }}>
                    Our White-label Platform{" "}
                  </span>
                </Typography>
                <Typography component="h5" className="mx-auto" sx={{ mt: 0 }}>
                  <span style={{ fontSize: "18px", fontWeight: 200 }}>
                    Delivers a Complete Brand Experience for Your Events{" "}
                  </span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: -3,
                    mt: 4,
                  }}
                >
                  <Link href="/selfOnboarding" passHref>
                    <Button
                      size="medium"
                      sx={{
                        background: "#61FC67",
                        my: 4,
                        color: "#000",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        px: 6,
                        "&:hover": {
                          background: "#61FC67",
                          color: "#000",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      <>
                        Get Started. It&apos;s FREE{" "}
                        <Image
                          src={chevron_right}
                          alt=""
                          width={35}
                          height={35}
                          style={{ marginLeft: 10 }}
                        />
                      </>
                    </Button>
                  </Link>
                </Box>
                <Typography component="h5">
                  <span style={{ fontSize: "18px" }}>
                    No Setup Costs or Subscription Fees, Just 5% Success Fee.
                  </span>
                </Typography>
              </Typography>
            </Stack>
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            // my: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Stack
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%", flexDirection: "row", mb: 5 }}
          >
            {/* {items.map((e) => (
              <BannerBottomItem
                key={nanoid(10)}
                data={e}
                selectedItem={selectedItem}
                setselectedItem={setselectedItem}
              />
            ))} */}
            <Swiper
              modules={[Navigation, FreeMode]}
              spaceBetween={2}
              slidesPerView={4.5}
              // freemode={true}
              navigation
              effect={"fade"}
              calculateHeight={true}
              autoplay={false}
              loop={false}
            >
              {items.map((e) => (
                <SwiperSlide key={nanoid(30)}>
                  <BannerBottomItem
                    data={e}
                    selectedItem={selectedItem}
                    setselectedItem={setselectedItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Stack>
        </Typography>

        <Box sx={SectionOneContainer}>
          <Box>
            <Typography
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Stack
                display="flex"
                justifyContent="center"
                sx={{ width: { md: "80%", sm: "100%" } }}
              >
                <Typography variant="h2">
                  <span>{selectedItem.title}</span>
                </Typography>
                <Typography variant="h4">{selectedItem.subTitle}</Typography>
                <Typography component="div" sx={imgContainer}>
                  <Image
                    src={selectedItem.image}
                    alt="section one"
                    style={{ width: "100%" }}
                  />
                </Typography>
              </Stack>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* SMALL TAB */}
      <Box
        sx={{
          width: "85%",
          mx: "auto",
          display: { md: "none", sm: "block", xs: "none" },
        }}
      >
        <Typography
          component="div"
          className="myvideo"
          sx={{ ...bannerContainerTab }}
        >
          <Typography component="div" className="myVideoContent">
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "80%",
                margin: `0 auto`,
              }}
            >
              <Typography variant="h1" sx={{ mb: 0 }}>
                Own Your Event Ticketing
              </Typography>
              <Typography
                variant="h1"
                sx={{ mb: 2, color: "#61fc67 !important" }}
              >
                With a FREE Web App
              </Typography>
              <Typography component="div" sx={{ width: "100%" }}>
                <Typography component="h5" className="mx-auto" sx={{ mb: 0 }}>
                  <strong style={{ fontSize: "20px" }}>
                    Beyond Ticketing:{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "18px", fontWeight: 200 }}>
                    Our White-label Platform{" "}
                  </span>
                </Typography>
                <Typography component="h5" className="mx-auto" sx={{ mt: 0 }}>
                  <span style={{ fontSize: "18px", fontWeight: 200 }}>
                    Delivers a Complete Brand Experience for Your Events{" "}
                  </span>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: -2,
                    mt: 4,
                  }}
                >
                  <Link href="/selfOnboarding" passHref>
                    <Button
                      size="medium"
                      sx={{
                        background: "#61FC67",
                        my: 4,
                        color: "#000",
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                        px: 6,
                        "&:hover": {
                          background: "#61FC67",
                          color: "#000",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      <>
                        Get Started. It&apos;s FREE{" "}
                        <Image
                          src={chevron_right}
                          alt=""
                          width={35}
                          height={35}
                          style={{ marginLeft: 10 }}
                        />
                      </>
                    </Button>
                  </Link>
                </Box>
                <Typography component="h5">
                  <span style={{ fontSize: "18px" }}>
                    No Setup Costs / Subscription Fees, Just 5% Success Fee.
                  </span>
                </Typography>
              </Typography>
            </Stack>
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            // my: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Stack
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%", flexDirection: "row", mb: 5 }}
          >
            <Swiper
              modules={[Navigation, FreeMode]}
              spaceBetween={3}
              slidesPerView={4.5}
              // freemode={true}
              navigation
              effect={"fade"}
              calculateHeight={true}
              autoplay={false}
              loop={false}
            >
              {items.map((e) => (
                <SwiperSlide key={nanoid(30)}>
                  <BannerBottomItem
                    data={e}
                    selectedItem={selectedItem}
                    setselectedItem={setselectedItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Stack>
        </Typography>

        <Box sx={SectionOneContainer}>
          <Box>
            <Typography
              component="div"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Stack
                display="flex"
                justifyContent="center"
                sx={{ width: { md: "80%", sm: "100%" } }}
              >
                <Typography variant="h2">
                  <span>{selectedItem.title}</span>
                </Typography>
                <Typography variant="h4">{selectedItem.subTitle}</Typography>
                <Typography component="div" sx={imgContainerTab}>
                  <Image
                    src={selectedItem.image}
                    alt="section one"
                    style={{ width: "50%" }}
                  />
                </Typography>
              </Stack>
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* PHONE */}
      <Box sx={{ width: "90%", mx: "auto", display: { sm: "none" } }}>
        <Typography
          component="div"
          className="myvideo"
          sx={{ ...bannerContainerMobile }}
        >
          <Typography component="div" className="myVideoContent">
            <Stack
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "90%",
                margin: `0 auto`,
              }}
            >
              <Typography variant="h1" sx={{ mb: 0, mt: 15 }}>
                Own Your Event Ticketing
              </Typography>
              <Typography
                variant="h1"
                sx={{ mb: 4, color: "#61fc67 !important" }}
              >
                With a FREE Web App
              </Typography>
              <Typography component="div" sx={{ width: "100%" }}>
                <Typography component="h5" className="mx-auto" sx={{ mb: 0 }}>
                  <strong style={{ fontSize: "16px" }}>
                    Beyond Ticketing:{" "}
                  </strong>{" "}
                  <span style={{ fontSize: "14px", fontWeight: 200 }}>
                    Our White-label Platform Delivers a Complete Brand
                    Experience for Your Events{" "}
                  </span>
                </Typography>
                {/* <Typography component="h5" className="mx-auto" sx={{ mt: 0 }}>
                  <span style={{ fontSize: "16px", fontWeight: 200 }}>
                    Delivers a Complete Brand Experience for Your Events{" "}
                  </span>
                </Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: -2,
                    mt: 8,
                  }}
                >
                  <Link href="/selfOnboarding">
                    <Button
                      size="large"
                      sx={{
                        background: "#61FC67",
                        my: 4,
                        color: "#000",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        px: 3,
                        "&:hover": {
                          background: "#61FC67",
                          color: "#000",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      <>
                        Get Started. It&apos;s FREE{" "}
                        {/* <Image
                          src={chevron_right}
                          alt=""
                          width={35}
                          height={35}
                          style={{ marginLeft: 10 }}
                        /> */}
                      </>
                    </Button>
                  </Link>
                </Box>
                <Typography component="h5">
                  <span style={{ fontSize: "14px" }}>
                    No Setup Costs or Subscription Fees, Just 5% Success Fee.
                  </span>
                </Typography>
              </Typography>
            </Stack>
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            // my: 5,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            mx: "auto",
          }}
        >
          <Stack
            display="flex"
            justifyContent="space-between"
            sx={{ width: "100%", flexDirection: "row", mb: 5 }}
          >
            <Swiper
              modules={[Navigation, FreeMode]}
              spaceBetween={1}
              slidesPerView={2.4}
              // freemode={true}
              navigation
              effect={"fade"}
              calculateHeight={true}
              autoplay={false}
              loop={false}
              initialSlide={2}
              // breakpoints={{
              //   1023: {
              //     width: 1023,
              //     slidesPerView: 3,
              //   },
              //   990: {
              //     width: 990,
              //     slidesPerView: 3,
              //   },
              //   768: {
              //     width: 768,
              //     slidesPerView: 3,
              //   },
              //   425: {
              //     width: 425,
              //     slidesPerView: 3,
              //   },
              // }}
            >
              {items.map((e) => (
                <SwiperSlide key={nanoid(30)}>
                  <BannerBottomItem
                    data={e}
                    selectedItem={selectedItem}
                    setselectedItem={setselectedItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Stack>
        </Typography>

        <Box sx={SectionOneContainerMobile}>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Stack
              display="flex"
              justifyContent="center"
              sx={{ width: { md: "80%", sm: "90%" } }}
            >
              <Typography variant="h2">
                <span>{selectedItem.title}</span>
              </Typography>
              <Typography variant="h4">{selectedItem.subTitle}</Typography>
              <Typography component="div" sx={imgContainerMobile}>
                <Image
                  src={selectedItem.image}
                  alt="section one"
                  style={{ width: "100%" }}
                />
              </Typography>
            </Stack>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BannerHome;
