import React, { Fragment } from "react";
import { Box, Divider, Typography } from "@mui/material";
import darkTheme from "../../../src/theme";

function First(props) {
  const FirstContainer = {
    borderLeft: `1px solid #4A4B4A`,
    pl: 3,
    mb: 4,
    mt: 6,
    // mr: 5,
    maxWidth: "60%",
    // display: "block",
    display: { lg: "block", xs: "none" },
    "& h4": {
      fontSize: 25,
      fontWeight: 500,
      color: darkTheme.palette.primary.main,
      textTransform: "capitalize",
      mb: 3,
      textAlign: "left",
    },
    "& h6": {
      fontSize: 16,
      fontWeight: 300,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  };

  const FirstContainerTab = {
    borderLeft: `1px solid #4A4B4A`,
    pl: 3,
    mb: 4,
    mt: 6,
    // mr: 5,
    maxWidth: "60%",
    // display: "block",
    display: { lg: "none", md: "block", xs: "none" },
    height: '100% !important',
    "& h4": {
      fontSize: 25,
      fontWeight: 500,
      color: darkTheme.palette.primary.main,
      textTransform: "capitalize",
      mb: 3,
      textAlign: "left",
    },
    "& h6": {
      fontSize: 16,
      fontWeight: 300,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  };

  const FirstContainerMobile = {
    display: { md: "none" },
    pb: 0,
    mb: 4,
    mt: 0,
    width: "100%",
    "& h4": {
      fontSize: 20,
      fontWeight: 500,
      color: darkTheme.palette.primary.main,
      textTransform: "capitalize",
      mb: 1,
      // textAlign: "center",
    },
    "& h6": {
      // textAlign: "center",
      fontSize: 16,
      fontWeight: 300,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  };

  const { title, content, last } = props;

  return (
    <>
      <Box sx={FirstContainer}>
        <Typography component="div">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{content}</Typography>
        </Typography>
      </Box>
      <Box sx={FirstContainerTab}>
        <Typography component="div">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{content}</Typography>
        </Typography>
      </Box>
      <Box sx={FirstContainerMobile}>
        <Typography component="div">
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{content}</Typography>
        </Typography>
        {!last && (
          <Divider
            sx={{
              background: "#030303",
              mt: 4,
              borderBottomWidth: 0.1,
              mx: "auto",
            }}
          />
        )}
      </Box>
    </>
  );
}

export default First;
