import React, { Fragment } from "react";
import { Box, Typography, Button, Stack, Container, Grid } from "@mui/material";
import darkTheme from "../../src/theme";
import Item from "./SectionThree/Item";
import Image from "next/image";

function SectionFour() {
  const SectionThreeContainer = {
    mt: 4,
    "& h2": {
      fontSize: 35,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 4,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={4}
        sx={{ background: "#1E1E1E", borderRadius: 5, mt: 10 }}
      >
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "80%",
              }}
            >
              <Box>
                <Typography
                  variant="h2"
                  sx={{ color: "#61FC67", mb: 2, mt: -2 }}
                >
                  Clubr Discovery Platform
                </Typography>
                <Typography variant="p" sx={{ color: "#fff" }}>
                  Get additional Discovery for your events and attract new
                  customers through the Clubr marketplace
                </Typography>
              </Box>
              <Button
                size="large"
                sx={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  width: "fit-content",
                  px: 5,
                  py: 2,
                  background: "#61FC67",
                  color: "#000",
                }}
              >
                Discover Nightlife
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12} sx={{ textAlign: "right" }}>
          <Box
            sx={{
              marginRight: "4rem",
              display: "inline-block",
            }}
          >
            <Image
              alt=""
              src={"/images/home/section6-image.png"}
              height={500}
              width={250}
              style={{
                width: "100% !important",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default SectionFour;
