import React, { Fragment } from "react";
import { Box, Typography, Button, Stack, Container, Grid } from "@mui/material";
import darkTheme from "../../src/theme";
import Item from "./SectionThree/Item";
import Image from "next/image";

const font = {
  fontFamily: "DM Sans, sans-serif",
};
const imageStyle = { width: "15%", height: "100%" };
const imageStyleMobile = { width: "50%", height: "100%" };

function SectionSeven() {
  const section8 = {
    display: { md: "block", xs: "none" },
    width: "85%",
    mx: "auto",
    mt: 10,
    // background: "#1f1f1f",
    // paddingTop: darkTheme.spacing(10),
    paddingBottom: darkTheme.spacing(10),
    [darkTheme.breakpoints.down("md")]: {
      paddingTop: darkTheme.spacing(6),
      paddingBottom: darkTheme.spacing(2),
    },
    "& h2": {
      fontSize: 45,
      color: "#61fc67",
      fontWeight: 700,
      marginBottom: darkTheme.spacing(3),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 400,
        marginBottom: darkTheme.spacing(1),
      },
      [darkTheme.breakpoints.down("sm")]: {
        fontSize: 20,
        fontWeight: 400,
        marginBottom: darkTheme.spacing(1),
      },
    },
    "& h4": {
      fontSize: 25,
      color: darkTheme.palette.common.white,
      fontWeight: 500,
      marginTop: darkTheme.spacing(3),
      marginBottom: darkTheme.spacing(1),
    },
    "& h6": {
      fontSize: 16,
      color: "#fff !important",
      fontWeight: 300,
      marginBottom: darkTheme.spacing(3),
      letterSpacing: 1,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 20,
        fontWeight: 300,
        letterSpacing: 0,
        marginBottom: darkTheme.spacing(0),
      },
    },
  };

  const section8Mobile = {
    display: { md: "none" },
    width: "90%",
    mx: "auto",
    mt: 3,
    mb: 6,
    textAlign: 'center',
    // background: "#1f1f1f",
    // paddingTop: darkTheme.spacing(10),
    paddingBottom: darkTheme.spacing(10),
    [darkTheme.breakpoints.down("md")]: {
      paddingTop: darkTheme.spacing(6),
      paddingBottom: darkTheme.spacing(2),
    },
    "& h2": {
      fontSize: 45,
      color: "#61fc67",
      fontWeight: 700,
      marginBottom: darkTheme.spacing(6),
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 400,
        marginBottom: darkTheme.spacing(1),
      },
      [darkTheme.breakpoints.down("sm")]: {
        fontSize: 24,
        fontWeight: 400,
        marginBottom: darkTheme.spacing(2),
      },
    },
    "& h4": {
      fontSize: 25,
      color: darkTheme.palette.common.white,
      fontWeight: 500,
      marginTop: darkTheme.spacing(3),
      marginBottom: darkTheme.spacing(1),
    },
    "& h6": {
      fontSize: 16,
      color: "#fff !important",
      fontWeight: 300,
      marginBottom: darkTheme.spacing(3),
      letterSpacing: 1,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 14,
        fontWeight: 300,
        letterSpacing: 0,
        marginBottom: darkTheme.spacing(0),
      },
    },
  };

  return (
    <>
      <Box sx={section8}>
        <Box>
          <Box
            sx={{
              // background: "#0c0c0c",
              px: { md: 10, sm: 5, xs: 5 },
              py: { md: 5, sm: 2, xs: 2 },
              borderRadius: 10,
              border: "5px solid #1E1E1E",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
              <img
                src={"/images/home/section7-image.png"}
                alt="section8"
                // width={1000}
                // height={1000}
                style={imageStyle}
              />
              <Box>
                <Typography variant="h2">
                  Clubr Support has your back.
                </Typography>
                <Typography variant="h6" className="desktopMode">
                  Our support is always accessible on Whatsapp also and ready to
                  help you with your queries.
                </Typography>
              </Box>
            </Box>
            {/* <Grid container spacing={2} alignItems="center">
              <Grid item md={3}></Grid>
              <Grid item md={9} alignSelf="center"></Grid>
            </Grid> */}
          </Box>
        </Box>
      </Box>
      <Box sx={section8Mobile}>
        <Box
          sx={{
            // background: "#0c0c0c",
            px: { md: 10, sm: 5, xs: 5 },
            py: { md: 5, sm: 2, xs: 2 },
            borderRadius: 10,
            border: "5px solid #1E1E1E",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              flexDirection: "column",
            }}
          >
            <img
              src={"/images/home/section7-image.png"}
              alt="section8"
              style={imageStyleMobile}
            />
            <Box>
              <Typography variant="h2">Clubr Support has your back.</Typography>
              <Typography variant="h6">
                Our support is always accessible on Whatsapp also and ready to
                help you with your queries.
              </Typography>
            </Box>
          </Box>
          {/* <Grid container spacing={2} alignItems="center">
              <Grid item md={3}></Grid>
              <Grid item md={9} alignSelf="center"></Grid>
            </Grid> */}
        </Box>
      </Box>
    </>
  );
}

export default SectionSeven;
