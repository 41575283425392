import React, { Fragment } from "react";
import { Box, Typography, Button, Stack, Container } from "@mui/material";
import darkTheme from "../../src/theme";
import Item from "./SectionThree/Item";

function SectionThree() {
  const SectionThreeContainerBigDesktop = {
    display: { xl: "block", xs: "none" },
    width: "85%",
    mx: "auto",
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 10,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainer = {
    display: { xl: "none", lg: "block", xs: "none" },
    width: "85%",
    mx: "auto",
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 10,
      width: "70%",
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainerTab = {
    display: { lg: "none", md: "block", xs: "none" },
    width: "90%",
    mx: "auto",
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 2,
      width: "90%",
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainerMobile = {
    display: { md: "none" },
    width: "90%",
    mx: "auto",
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 2,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const points = [
    {
      title: "Leverage your Organic Traction",
      content:
        "Turn Social Media Buzz into Ticket Sales: Let Followers Book Directly on Your Website and Save on Marketplace Fees!",
    },
    {
      title: "Direct Access to Funds",
      content:
        "Clubr enables venues to access funds quickly through T+1 settlements, allowing them to reinvest pre-sale revenue in upcoming events.",
    },
    {
      title: "Your Event your Data",
      content:
        "Clubr provides full access to customer data, enabling you to utilize valuable insights to run targeted and effective campaigns.",
    },
  ];

  return (
    <>
      {/* BIG DESKTOP */}
      <Box sx={SectionThreeContainerBigDesktop}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Everything you need to make the most out of every Event
            </Typography>
          </Stack>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            {points.map((e, i) => (
              <Item key={i} title={e.title} content={e.content} />
            ))}
          </Typography>
        </Box>
      </Box>

      {/* DESKTOP */}
      <Box sx={SectionThreeContainer}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Everything you need to make the most out of every Event
            </Typography>
          </Stack>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            {points.map((e, i) => (
              <Item key={i} title={e.title} content={e.content} />
            ))}
          </Typography>
        </Box>
      </Box>

      {/* TAB */}
      <Box sx={SectionThreeContainerTab}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Everything you need to make the most out of every Event
            </Typography>
          </Stack>
          <Typography
            component="div"
            sx={{
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "stretch",
              pt: 5,
              gap: "16px",
            }}
          >
            {points.map((e, i) => (
              <Item key={i} title={e.title} content={e.content} />
            ))}
          </Typography>
        </Box>
      </Box>

      {/* PHONE */}
      <Box sx={SectionThreeContainerMobile}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Everything you need to make the most out of every Event
            </Typography>
          </Stack>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 5,
            }}
          >
            {points.map((e, i) => (
              <Item key={i} title={e.title} content={e.content} />
            ))}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default SectionThree;
