import React, { Fragment } from "react";
import { Box, Typography, Button, Stack, Container, Grid } from "@mui/material";
import darkTheme from "../../src/theme";
import Item from "./SectionThree/Item";
import Image from "next/image";
// import ItemImage from "../../public/images/home/item-1-image.svg";
import ItemImage from "../../public/images/home/ai-revolution-section-image.png";

function SectionFour() {
  const SectionThreeContainerBigDesktop = {
    width: "85%",
    mx: "auto",
    mt: 10,
    pr: 10,
    pl: 5,
    borderRadius: 5,
    border: "5px solid #1E1E1E",
    "& h2": {
      fontSize: 40,
      fontWeight: 700,
      color: "#61FC67",
      mb: 5,
      // width: { md: "50%", sm: "90%" },
      // margin: "0 auto",
      // [darkTheme.breakpoints.down("md")]: {
      //   fontSize: 25,
      //   fontWeight: 500,
      // },
    },
    "& h6": {
      fontSize: 16,
      fontWeight: 300,
      mb: 5,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  };

  const SectionThreeContainer = {
    width: "85%",
    mx: "auto",
    mt: 10,
    pr: 10,
    pl: 5,
    pb: 5,
    borderRadius: 5,
    border: "5px solid #1E1E1E",
    "& h2": {
      fontSize: 40,
      fontWeight: 700,
      color: "#61FC67",
      mb: 5,
      // width: { md: "50%", sm: "90%" },
      // margin: "0 auto",
      // [darkTheme.breakpoints.down("md")]: {
      //   fontSize: 25,
      //   fontWeight: 500,
      // },
    },
    "& h6": {
      fontSize: 16,
      fontWeight: 300,
      mb: 5,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  };

  const SectionThreeContainerTab = {
    width: "90%",
    mx: "auto",
    mt: 8,
    px: 3,
    pt: 3,
    pb: 2,
    borderRadius: 5,
    border: "5px solid #1E1E1E",
    "& h2": {
      fontSize: 35,
      fontWeight: 700,
      color: "#61FC67",
      mb: 5,
      textAlign: "center",
      // width: { md: "50%", sm: "90%" },
      // margin: "0 auto",
      // [darkTheme.breakpoints.down("md")]: {
      //   fontSize: 25,
      //   fontWeight: 500,
      // },
    },
    "& h6": {
      fontSize: 18,
      fontWeight: 300,
      mb: 5,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
  };

  const SectionThreeContainerMobile = {
    width: "90%",
    mx: "auto",
    mt: 4,
    px: 3,
    pt: 3,
    pb: 2,
    borderRadius: 5,
    border: "5px solid #1E1E1E",
    "& h2": {
      fontSize: 24,
      fontWeight: 700,
      color: "#61FC67",
      mb: 5,
      textAlign: "center",
      // width: { md: "50%", sm: "90%" },
      // margin: "0 auto",
      // [darkTheme.breakpoints.down("md")]: {
      //   fontSize: 25,
      //   fontWeight: 500,
      // },
    },
    "& h6": {
      fontSize: 16,
      fontWeight: 300,
      mb: 5,
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  };

  return (
    <>
      {/* BIG DESKTOP */}
      <Box sx={{ display: { xl: "block", xs: "none" } }}>
        <Grid container spacing={4} sx={SectionThreeContainerBigDesktop}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box>
              <Typography variant="h2">
                AI Revolutionizes Your Support & Analytics
              </Typography>
              <Typography variant="h6">
                Powerful Generative AI-powered ClubrBot exclusively trained on
                your events
              </Typography>
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Reservations
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    General Information
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Ticket Resending
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Upcoming Events
                  </Typography>
                </li>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ textAlign: "right" }}>
            <img
              // src={"/images/home/section-4-image.png"}
              // src={ItemImage}
              src="../../images/home/ai-revolution-section-image.png"
              // height={500}
              // width={500}
              style={{ width: "100% !important", width: "70%" }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* DESKTOP */}
      <Box sx={{ display: { xl: "none", lg: "block", xs: "none" } }}>
        <Grid container spacing={4} sx={SectionThreeContainer}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box>
              <Typography variant="h2">
                AI Revolutionizes Your Support & Analytics
              </Typography>
              <Typography variant="h6">
                Powerful Generative AI-powered ClubrBot exclusively trained on
                your events
              </Typography>
              <Typography component="ul" sx={{ pl: 2 }}>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Reservations
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    General Information
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Ticket Resending
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h6"
                    component="strong"
                    sx={{ fontSize: 16 }}
                  >
                    Upcoming Events
                  </Typography>
                </li>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ textAlign: "right" }}>
            <img
              // src={"/images/home/section-4-image.png"}
              // src={ItemImage}
              src="../../images/home/ai-revolution-section-image.png"
              // height={500}
              // width={500}
              style={{ width: "100% !important", width: "70%" }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* TAB */}
      <Box sx={{ display: { lg: "none", sm: "block", xs: "none" } }}>
        <Box sx={SectionThreeContainerTab}>
          <Typography variant="h2">
            AI Revolutionizes Your Support & Analytics
          </Typography>
          <Typography variant="h6">
            Powerful Generative AI-powered ClubrBot exclusively trained on your
            events
          </Typography>
          <br />
          <Typography component="ul" sx={{ pl: 2, display: "block" }}>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 18 }}>
                Reservations
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 18 }}>
                General Information
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 18 }}>
                Ticket Resending
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 18 }}>
                Upcoming Events
              </Typography>
            </li>
          </Typography>
          <Box sx={{ textAlign: "center", marginTop: "3rem" }}>
            <img
              src="../../images/home/ai-revolution-section-image.png"
              style={{
                width: "70%",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* PHONE */}
      <Box sx={{ display: { sm: "none" } }}>
        <Grid container spacing={4} sx={SectionThreeContainerMobile}>
          <Typography variant="h2">
            AI Revolutionizes Your Support & Analytics
          </Typography>
          <Typography variant="h6">
            Powerful Generative AI-powered ClubrBot exclusively trained on your
            events
          </Typography>
          <Typography component="ul" sx={{ pl: 2 }}>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 14 }}>
                Reservations
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 14 }}>
                General Information
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 14 }}>
                Ticket Resending
              </Typography>
            </li>
            <li>
              <Typography variant="h6" component="strong" sx={{ fontSize: 14 }}>
                Upcoming Events
              </Typography>
            </li>
          </Typography>
          <img
            // src={"/images/home/section-4-image.png"}
            // src={ItemImage}
            src="../../images/home/ai-revolution-section-image.png"
            // height={500}
            // width={500}
            style={{ width: "100%", marginTop: "3rem" }}
          />
        </Grid>
      </Box>
    </>
  );
}

export default SectionFour;
