import React, { Fragment } from "react";
import { Box, Typography, Stack, Container } from "@mui/material";
import darkTheme from "../../src/theme";
import SectionOneImage from "../../public/images/home/SectionOne.gif";
import Image from "next/image";

function sectionOne() {
  const SectionOneContainer = {
    background: "#202020",
    p: 5,
    mt: 10,
    mb: 10,
    borderRadius: 6,
    [darkTheme.breakpoints.down("md")]: {
      mt: 5,
      mb: 10,
    },
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      textTransform: "capitalize",
      mb: 2,
      "& span": {
        color: darkTheme.palette.primary.main,
      },
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
    "& h4": {
      fontSize: 18,
      fontWeight: 500,
      textAlign: "center",
      color: darkTheme.palette.common.white,
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
  };

  const imgContainer = {
    mt: 3,
    [darkTheme.breakpoints.down("md")]: {
      transform: `scale(1.35)`,
    },
  };
  return (
    <Fragment>
      <Box sx={SectionOneContainer}>
        <Container>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Stack
              display="flex"
              justifyContent="center"
              sx={{ width: { md: "80%", sm: "100%" } }}
            >
              <Typography variant="h2">
                <span>Critical Insights for Smarter Decisions.</span>
              </Typography>
              <Typography variant="h4">
                Real-Time Sales, Check-In Stats, and Gate Intelligence
              </Typography>
              <Typography component="div" sx={imgContainer}>
                <Image src={SectionOneImage} alt="section one" />
              </Typography>
            </Stack>
          </Typography>
        </Container>
      </Box>
    </Fragment>
  );
}

export default sectionOne;
