import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, Stack, Container } from "@mui/material";
import darkTheme from "../../src/theme";
import ItemOne from "./SectionTwo/ItemOne";
import ItemTwo from "./SectionTwo/ItemTwo";

function SectionTwo() {
  const SectionTwoContainer = {
    display: { lg: "block", xs: "none" },
    py: 5,
    mt: 5,
    mb: 10,
    width: "85%",
    mx: "auto",
    borderRadius: 6,
    [darkTheme.breakpoints.down("md")]: {
      mt: 10,
      mb: 10,
    },
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      textTransform: "capitalize",
      mb: 10,
      "& span": {
        color: darkTheme.palette.primary.main,
      },
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  const SectionTwoContainerTab = {
    display: { lg: 'none', md: "block", xs: 'none' },
    py: 5,
    mt: 5,
    mb: 10,
    width: "90%",
    mx: "auto",
    borderRadius: 6,
    [darkTheme.breakpoints.down("md")]: {
      mt: 3,
      mb: 0,
    },
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      textTransform: "capitalize",
      mb: 3,
      "& span": {
        color: darkTheme.palette.primary.main,
      },
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const SectionTwoContainerMobile = {
    display: { md: "none" },
    py: 5,
    mt: 5,
    mb: 10,
    width: "90%",
    mx: "auto",
    borderRadius: 6,
    [darkTheme.breakpoints.down("md")]: {
      mt: 3,
      mb: 0,
    },
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      textTransform: "capitalize",
      mb: 3,
      "& span": {
        color: darkTheme.palette.primary.main,
      },
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const [box1Hovered, setbox1Hovered] = useState(true);
  const [box2Hovered, setbox2Hovered] = useState(false);

  useEffect(() => {
    console.log({ box1Hovered, box2Hovered });
  }, [box1Hovered, box2Hovered]);

  return (
    <>
    {/* DESKTOP */}
      <Box sx={SectionTwoContainer}>
        <Box>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Stack
              display="flex"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="h2">Save Time & Get More Done</Typography>
              <Stack
                display="flex"
                justifyContent="space-between"
                sx={{ flexDirection: "row", height: "100%" }}
              >
                <ItemOne
                  box1Hovered={box1Hovered}
                  box2Hovered={box2Hovered}
                  setbox1Hovered={setbox1Hovered}
                  setbox2Hovered={setbox2Hovered}
                />
                <ItemTwo
                  box1Hovered={box1Hovered}
                  box2Hovered={box2Hovered}
                  setbox1Hovered={setbox1Hovered}
                  setbox2Hovered={setbox2Hovered}
                />
              </Stack>
            </Stack>
          </Typography>
        </Box>
      </Box>

      {/* TAB */}
      <Box sx={SectionTwoContainerTab}>
        <Box>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Stack
              display="flex"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="h2">Save Time & Get More Done</Typography>
              <ItemOne />
              <ItemTwo />
            </Stack>
          </Typography>
        </Box>
      </Box>

      {/* PHONE */}
      <Box sx={SectionTwoContainerMobile}>
        <Box>
          <Typography
            component="div"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Stack
              display="flex"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              <Typography variant="h2">Save Time & Get More Done</Typography>
              <ItemOne />
              <ItemTwo />
            </Stack>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default SectionTwo;
