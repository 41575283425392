import React, { Fragment, useEffect, useRef, useState } from "react";
import { Box, Typography, Button, Stack, Container, Grid } from "@mui/material";
import darkTheme from "../../src/theme";
import Item from "./SectionThree/Item";
import Image from "next/image";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { nanoid } from "nanoid";

function SectionFive() {
  const SectionThreeContainerBigDesktop = {
    display: { xl: "block", xs: "none" },
    mt: 10,
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 10,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainer = {
    display: { xl: "none", lg: "block", xs: "none" },
    mt: 10,
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 10,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 25,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainerTab = {
    display: { lg: "none", sm: "block", xs: "none" },
    width: "90%",
    mx: "auto",
    mt: 10,
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 7,
      width: "100%",
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const SectionThreeContainerMobile = {
    display: { sm: "none" },
    width: "90%",
    mx: "auto",
    mt: 6,
    "& h2": {
      fontSize: 40,
      textAlign: "center",
      fontWeight: 700,
      color: darkTheme.palette.common.white,
      mb: 5,
      width: { md: "50%", sm: "90%" },
      margin: "0 auto",
      [darkTheme.breakpoints.down("md")]: {
        fontSize: 24,
        fontWeight: 500,
      },
    },
  };

  const [items, setitems] = useState([
    {
      id: 1,
      image: "/images/home/Manage Tables.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Assign-tables.mp4",
    },
    {
      id: 2,
      image: "/images/home/Group checkins.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Group_Checkins.mp4",
    },
    {
      id: 3,
      image: "/images/home/Campaign.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Campaign.mp4",
    },
    {
      id: 4,
      image: "/images/home/Digital Valet.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Digital_Valet_Demo.mp4",
    },
    {
      id: 5,
      image: "/images/home/Guestlist.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Guestlist.mp4",
    },
    {
      id: 6,
      image: "/images/home/Smart Walkins.svg",
      video:
        "https://clubrstorage.blob.core.windows.net/clubr-img/clubr-site/Smart-Walkins.mp4",
    },
  ]);

  return (
    <>
      {/* BIG DESKTOP */}
      <Box sx={SectionThreeContainerBigDesktop}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Ultimate Productivity Tools <br />
              for Streamlined Operations
            </Typography>
          </Stack>
          <Swiper
            style={{ marginTop: "5rem" }}
            modules={[Navigation, FreeMode]}
            spaceBetween={40}
            slidesPerView={4.5}
            // freemode={true}
            navigation
            effect={"fade"}
            calculateHeight={true}
            autoplay={true}
            dela
            loop={false}
            // breakpoints={{
            //   1023: {
            //     width: 1023,
            //     slidesPerView: 3,
            //   },
            //   990: {
            //     width: 990,
            //     slidesPerView: 3,
            //   },
            //   768: {
            //     width: 768,
            //     slidesPerView: 3,
            //   },
            //   425: {
            //     width: 425,
            //     slidesPerView: 3,
            //   },
            // }}
          >
            {items.map((e) => (
              <SwiperSlide key={nanoid(10)}>
                <Slide e={e} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      {/* DESKTOP */}
      <Box sx={SectionThreeContainer}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Ultimate Productivity Tools <br />
              for Streamlined Operations
            </Typography>
          </Stack>
          <Swiper
            style={{ marginTop: "5rem" }}
            modules={[Navigation, FreeMode]}
            spaceBetween={40}
            slidesPerView={4.5}
            // freemode={true}
            navigation
            effect={"fade"}
            calculateHeight={true}
            autoplay={true}
            dela
            loop={false}
            // breakpoints={{
            //   1023: {
            //     width: 1023,
            //     slidesPerView: 3,
            //   },
            //   990: {
            //     width: 990,
            //     slidesPerView: 3,
            //   },
            //   768: {
            //     width: 768,
            //     slidesPerView: 3,
            //   },
            //   425: {
            //     width: 425,
            //     slidesPerView: 3,
            //   },
            // }}
          >
            {items.map((e) => (
              <SwiperSlide key={nanoid(10)}>
                <SlideSmallDesktop e={e} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      {/* TAB */}
      <Box sx={SectionThreeContainerTab}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Ultimate Productivity Tools <br /> for Streamlined Operations
            </Typography>
          </Stack>
          <Swiper
            style={{ marginTop: "2rem" }}
            modules={[Navigation, FreeMode]}
            spaceBetween={30}
            slidesPerView={1.8}
            // freemode={true}
            navigation
            effect={"fade"}
            calculateHeight={true}
            autoplay={false}
            loop={false}
          >
            {items.map((e) => (
              <SwiperSlide key={nanoid(10)}>
                <SlideTab e={e} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>

      {/* PHONE */}
      <Box sx={SectionThreeContainerMobile}>
        <Box>
          <Stack display="flex" justifyContent="center">
            <Typography variant="h2">
              Ultimate Productivity Tools for Streamlined Operations
            </Typography>
          </Stack>
          <Swiper
            style={{ marginTop: "2rem" }}
            modules={[Navigation, FreeMode]}
            spaceBetween={30}
            slidesPerView={1.5}
            // freemode={true}
            navigation
            effect={"fade"}
            calculateHeight={true}
            autoplay={false}
            loop={false}
          >
            {items.map((e) => (
              <SwiperSlide key={nanoid(10)}>
                <SlideMobile e={e} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
}

const Slide = ({ e }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    console.log("hovered");

    setIsHovered(true);
    // Play the video when hovered
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Pause the video when hover ends
    videoRef.current.pause();
    videoRef.current.currentTime = 0; // Reset to the beginning
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: "100%",
        // height: "1000px",
        width: "100%",
        background: "#1e1e1e",
        borderRadius: 5,
      }}
    >
      {!isHovered && <Image alt="" src={e.image} height={900} width={500} />}

      {/* Video that plays when hovered */}
      <video
        ref={videoRef}
        // src="https://www.w3schools.com/html/mov_bbb.mp4"
        src={e.video}
        style={{
          borderRadius: "20px",
          width: "100%",
          // height: "100%",
          // height: "900px",
          height: "45rem",
          objectFit: "cover",
          display: isHovered ? "block" : "none", // Hide video when not hovered
          position: "absolute",
          top: 0,
          left: 0,
        }}
        muted // Optional: Keeps the video muted
        loop // Optional: Loops the video when hovered
      />
    </Box>
  );
};

const SlideSmallDesktop = ({ e }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    console.log("hovered");

    setIsHovered(true);
    // Play the video when hovered
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Pause the video when hover ends
    videoRef.current.pause();
    videoRef.current.currentTime = 0; // Reset to the beginning
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: "100%",
        // height: "1000px",
        width: "100%",
        background: "#1e1e1e",
        borderRadius: 5,
      }}
    >
      {!isHovered && <Image alt="" src={e.image} height={900} width={500} />}

      {/* Video that plays when hovered */}
      <video
        ref={videoRef}
        // src="https://www.w3schools.com/html/mov_bbb.mp4"
        src={e.video}
        style={{
          borderRadius: "20px",
          width: "100%",
          // height: "100%",
          // height: "900px",
          height: "28rem",
          objectFit: "cover",
          display: isHovered ? "block" : "none", // Hide video when not hovered
          position: "absolute",
          top: 0,
          left: 0,
        }}
        muted // Optional: Keeps the video muted
        loop // Optional: Loops the video when hovered
      />
    </Box>
  );
};

const SlideTab = ({ e }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    console.log("hovered");

    setIsHovered(true);
    // Play the video when hovered
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Pause the video when hover ends
    videoRef.current.pause();
    videoRef.current.currentTime = 0; // Reset to the beginning
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: "100%",
        width: "100%",
        background: "#1e1e1e",
        borderRadius: 5,
      }}
    >
      {!isHovered && <Image alt="" src={e.image} height={900} width={500} />}

      {/* Video that plays when hovered */}
      <video
        ref={videoRef}
        // src="https://www.w3schools.com/html/mov_bbb.mp4"
        src={e.video}
        style={{
          borderRadius: "20px",
          width: "100%",
          // height: "90% !important",
          // height: "900px",
          height: "50rem",
          objectFit: "cover",
          display: isHovered ? "block" : "none", // Hide video when not hovered
          position: "absolute",
          top: 0,
          left: 0,
        }}
        muted // Optional: Keeps the video muted
        loop // Optional: Loops the video when hovered
      />
    </Box>
  );
};

const SlideMobile = ({ e }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    console.log("hovered");

    setIsHovered(true);
    // Play the video when hovered
    videoRef.current.play();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Pause the video when hover ends
    videoRef.current.pause();
    videoRef.current.currentTime = 0; // Reset to the beginning
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: "100%",
        width: "100%",
        background: "#1e1e1e",
        borderRadius: 5,
      }}
    >
      {!isHovered && <Image alt="" src={e.image} height={900} width={500} />}

      {/* Video that plays when hovered */}
      <video
        ref={videoRef}
        // src="https://www.w3schools.com/html/mov_bbb.mp4"
        src={e.video}
        style={{
          borderRadius: "20px",
          width: "100%",
          height: "24rem",
          objectFit: "cover",
          display: isHovered ? "block" : "none", // Hide video when not hovered
          position: "absolute",
          top: 0,
          left: 0,
        }}
        muted // Optional: Keeps the video muted
        loop // Optional: Loops the video when hovered
      />
    </Box>
  );
};

export default SectionFive;
